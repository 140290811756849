<template>
<div id="logoControlsContainer">
    Controls
</div>
</template>

<script>
export default {
    name: 'LogoControls',
    components: {
        
    }
    
}
</script>

<style lang="scss" scoped>
#logoControlsContainer {
    background-color: red;
    // width: 30vw; 
    height: 10%; 
}
</style>