<template>
<div id="logoInputContainer">
    Input
    <font-awesome-icon icon="fa-solid fa-user-secret" />
</div>
</template>

<script>
export default {
    name: 'LogoInput',
    components: {
        
    }
    
}
</script>

<style lang="scss" scoped>
#logoInputContainer {
    background-color: green;
    // width: 30vw; 
    height: 30%; 
}
</style>