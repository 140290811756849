<template>
<div id="main-menu-container">
    <button id="main-menu-open-close" @click="open = !open"> 
        <div v-if='!open'>Open</div>
        <div v-else>Close</div>
    </button>
    <div id="main-menu" v-bind:class="{hidden: !open, 'main-menu-opening': open}">
        <div id="main-menu-content"> 
            Main Menu
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'MainMenu',
    data() {
        return {
            open: false,
        }
    },
}
</script>

<style lang="scss" scoped>

#main-menu-open-close {
    position: absolute; 
    right: 1vw;            // todo: setup up local variable on doc for scrollbar width to be able to ofset
    top: 1vh; 
    z-index: 200;
}
#main-menu-container{
    position: absolute;
    display: grid;
    place-content: center; 
    width: 98vw;
    height: 100vh; 
}
#main-menu {
    display: relative; 
    width: 90vw; 
    height: 90vh; 
    margin-left: auto; 
    margin-right: auto;
    // z-index: 100; 
    // clip-path: circle(100% at top right);  // todo.. why? Replace with keyframes!
    transition: clip-path ease-in-out 700ms;
    
    /* From https://css.glass */
    background: rgba(21, 255, 227, 0.23);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.7px);
    -webkit-backdrop-filter: blur(7.7px);
    border: 1px solid rgba(21, 255, 227, 0.26);
}
#main-menu-content {
    display: grid;
    place-content: center; 
    width: 100%;
    height: 100%;
}

.main-menu-opening {
    clip-path: circle(250% at top right); 
}
.hidden {
    clip-path: circle(0% at top right); 
    // display: none; 
}
</style>