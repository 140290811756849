<template>
<div id="logoToolsContainer">
    Tools
</div>
</template>

<script>
export default {
    name: 'LogoControls',
    components: {
        
    }
    
}
</script>

<style lang="scss" scoped>
#logoToolsContainer {
    background-color: brown;
    // width: 30vw; 
    height: 10%; 
}
</style>