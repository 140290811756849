<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <LogoEditor/>
  </div>
</template>

<script>
// @ is an alias to /src
import LogoEditor from '@/views/LogoEditor.vue'

export default {
  name: 'HomeView',
  components: {
    LogoEditor
  }
}
</script>
