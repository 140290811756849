<template>
<div id="logoOutContainer">
    Output 
    asdfajsdfl

</div>
</template>

<script>
export default {
    name: 'LogoOutput',
}
</script>

<style lang="scss" scoped>
#logoOutContainer {
    background-color: pink;
    width: 100%; 
    height: 80%; 
}
</style>