<template>
<div id="logo-page-container">
    <div id="logo-editor-container">
        <div id="logo-out">
            <logo-output/>
            <logo-tools/> 
        </div>

        <div id="input">
            <logo-files/>
            <logo-input/> 
            <logo-controls/>
        </div>
    </div>

    <folders/>
</div>
</template>

<script>
import LogoFiles from '@/components/Logo/LogoFiles.vue'
import LogoInput from '@/components/Logo/LogoInput.vue'
import LogoOutput from '@/components/Logo/LogoOutput.vue'
import LogoControls from '@/components/Logo/LogoControls.vue'
import LogoTools from '@/components/Logo/LogoTools.vue'
import Folders from '@/components/Folder/Folder.vue'

export default {
    name: 'LogoEditor',
    components: {
        LogoFiles,
        LogoInput, 
        LogoOutput,
        LogoControls,
        LogoTools,
        Folders,
    }
}
</script>

<style lang="scss" scoped>

#logo-page-container {
    display:relative; 
    width: 100vw; 
    max-width: 100%;
}
#logo-editor-container {
    display: flex;
    justify-content: space-between;
    background-color: yellow;
    width: 100%; 
    height: 100vh; 
}
#input {
    display: flex; 
    flex-direction: column;
    // justify-content: space-between;
    height: 100%; 
    width: 30%; 

}
#logo-out {
    height: 100%; 
    width: 100%
}
</style>