<template>
<div id="foldersContainer">
    
	<div class="tabs">
		<tab :tabName="1">Hello </tab>
		<tab :tabName="2">Hello </tab>
		<tab :tabName="3">Hello </tab>
		<tab :tabName="4">Hello </tab>
    </div>

	<div class="content" :class="{'content-open': contentOpen}">
        <div class="content-inner" id="tab-1">
            <div class="content-page">
                lorem
            </div>
        </div>
        <div class="content-inner" id="tab-2">
            <div class="content-page">
                lorem
            </div>
        </div>
        <div class="content-inner" id="tab-3">
            <div class="content-page">
                lorem
            </div>
        </div>
        <div class="content-inner" id="tab-4">
            <div class="content-page">
                lorem
            </div>
        </div>
    </div>

</div>
</template>

<script>
import Tab from "./Tab.vue"

export default {
    name: 'Folders',
	components: {
		Tab,
	},
	data() {
		return {
			contentOpen: false
		}
	},
	mounted () {
		var myThis = this;  
		this.$root.$on('opening_tab', function(tab_name) {
            console.log("In folder, " + tab_name + " being opened")
            
			myThis.contentOpen = true; 
        });
	},
}
</script>

<style lang="scss" scoped>
// .temp {
// 	background-color:red;
// 	width: 200px;
// 	height: 100px;
// }

#foldersContainer {
	position: relative;
    // background-color: purple;
    // width: 100%; 
    // height: 100%; 
	margin-inline: 1rem;
	// margin: 1rem; 
    // text-align: center; 
	transform: translateY(max(-8vh, -40px));
}

.tabs {
	display: flex;
	transform: translate(2rem, 0px);
	width: 80%;
	// background-color: pink;
}
.content {
	// display: inline-block;
	// width: 90vw;
	height: 100vh;
	width: 100%;
	// text-align: center;
	// margin: 0 auto; 
	// margin-left: auto;
	// margin-right: auto; 
	border-radius: 1rem;
	border: 1px solid black; 
	border-top: 0px;
    background-color: rgb(250, 234, 174);
	transform: translateY(-30px);
	transform: translateY(max(-10vh, -10px));
	text-align: center;
	z-index: 0;
	
	// https://html-css-js.com/css/generator/box-shadow/
	// -webkit-box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.62); 
	// box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.62);
}

// todo change to keyframes for up and down pop
.content-open {
	transform: translateY(-1.1rem); 
}
.content-inner {
	background-color: transparent;
	width: 100%;
	height: 100%;
	padding: 1rem; 
	
	box-sizing: border-box;
}
.content-page {
	background-color: white;
	width: 100%;
	height: 100%;
}
// .tab {
	// font-family: "Patrick Hand";
	// text-transform: uppercase;
	// line-height: 0.8;
	// display: inline-block;
	// margin-left: -15px;
	// filter: drop-shadow(0px -3px 2px rgba(0, 0, 0, 0.05));
	// border: none;
	// // border-radius: 6px 6px 0 0;
	// position: relative;
	// margin-right: 3.5rem;
	// background: var(--background);
	// white-space: nowrap;
	// cursor: pointer;
	// background: var(--background);
	// &:focus {
	// 	outline: none;
	// 	span {
	// 		border-bottom: 2px solid;
	// 		border-radius: 0;
	// 	}
	// }
	// &:first-of-type {
	// 	margin-left: 0px;
	// }
	// div {
	// 	background: var(--background);
	// 	padding: 6px 0;
	// 	position: relative;
	// 	z-index: 10;
	// }
	// span {
	// 	display: inline-block;
	// 	border: 2px solid transparent;
	// 	padding: 6px 15px 6px;
	// 	border-radius: 5px;
	// 	z-index: 5;
	// 	position: relative;
	// 	font-size: 1.4em; 
    //     // 140%;
	// 	color: #fff;
	// 	min-width: 4rem;
	// }
	// &:before,
	// &:after {
	// 	content: "";
	// 	height: 100%;
	// 	position: absolute;
	// 	background: var(--background);
	// 	border-radius: 8px 8px 0 0;
	// 	width: 20px;
	// 	top: 0;
	// }
	// &:before {
	// 	right: -8px;
	// 	transform: skew(15deg);
	// 	border-radius: 0 8px 0 0;
	// }
	// &:after {
	// 	transform: skew(-15deg);
	// 	left: -8px;
	// 	border-radius: 8px 0 0 0;
	// }
	// &.active {
	// 	z-index: 50;
	// 	position: relative;
	// 	span {
	// 		background: white;
	// 		box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
	// 		border-radius: 5px;
	// 		color: black;
	// 	}
	// }
// }

// .content {
// 	border-radius: 10px;
// 	position: relative;
// 	width: 100%;
// 	#tab-1 {
// 		display: block;
// 	}
// 	&:before {
// 		content: "";
// 		width: 100%;
// 		height: 100%;
// 		position: absolute;
// 		z-index: -1;
// 		top: 0;
// 		left: 0;
// 		border-radius: inherit;
// 	}
// 	&__inner {
// 		font-family: "Yomogi", sans-serif;
// 		display: none;
// 		background: var(--background);
// 		border-radius: inherit;
// 		padding: 1rem;
// 		filter: drop-shadow(0px -2px 2px rgba(0, 0, 0, 0.1));
// 		z-index: 5;
// 	}
// }
// .foldersContainer {
// 	margin: 0.1rem 0.1rem;
// 	max-width: 100vw;//50rem;
// 	width: 100%;
// 	scrollbar-height: none; /* Firefox */
// 	-ms-overflow-style: none; /* IE 10+ */
// 	::-webkit-scrollbar {
// 		background: transparent; /* Chrome/Safari/Webkit */
// 		display: none;
// 	}
// }

// .tabs {
// 	// padding: 2rem 0 0 0;
// 	width: 100%;
// 	// margin: 0 0.5rem;
// 	overflow-x: auto;
//     width: 82%;
// 	// width: calc(100% - 1rem);
// 	white-space: nowrap;
// 	// @media(min-width: 50rem) {
// 	// 	// margin: 0 2rem;
// 	// 	width: calc(100% - 4rem);
// 	// }
// }


//  .foldersContainer {
// 	.tab {
// 		--background: linear-gradient(to bottom, #fee9a5, #f9d877);
// 		span {
// 			color: black;
// 		}
// 		&:focus span {
// 			border-bottom-color: black;
// 		}
// 		&.active:focus span {
// 			border-color: black;
// 		}
// 	}
// 	.content__inner {
// 		--background: linear-gradient(to bottom, #f9d877, #fee9a5);
// 	}
// }

</style>