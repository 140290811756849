<template>
<div id="logoFilesContainer">
    Files
</div>
</template>

<script>
export default {
    name: 'LogoFiles',
    components: {
        
    }
    
}
</script>

<style lang="scss" scoped>
#logoFilesContainer {
    background-color: purple;
    // width: 100%; 
    height: 60%; 
}
</style>